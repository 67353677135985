import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import './Createform.css';
import axios from 'axios'


const initialState = {
    name: '',
    sename:'',
    familyname:'',
    register: '',
    phone: '',
    bank: '',
    account: '',
    job:'',
    local:'',
    location:'',
    sex:'',
    age:'1',
    city:"",
    email:'',
    date:'',
    message: 'Амжилттай бүргэгдлээ!',
    type: 'success',
    nameError: '',
    senameError:'',
    familynameError:'',
    registerError: '',
    phoneError: '',
    accountError: '',
    bankError: '',
    jobError:'',
    localError:'',
    locationError:'',
    registered: '',
    sexError:'',
    ageError:'',
    cityError:'',
    emailError:'',
    dateError:'',
    isSubmitting: false
}

class CreateForm extends Component {
    constructor(props){
        super(props)
        this.state = initialState;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

     register = newUser => {
        return axios
        .post('/createAccount', {
            custName: newUser.name,
            seName: newUser.sename,
            registerId: newUser.register,
            bankName: newUser.bank,
            accountNumber : newUser.account,
            familyName: newUser.familyname,
            phoneNumber: newUser.phone,
            jobText: newUser.job,
            localText: newUser.local,
            locationText: newUser.location,
            ageName: newUser.age,
            sexName: newUser.sex,
            dateNumber: newUser.date,
            cityName: newUser.city,
            emailName: newUser.email
          })
          .then(response => {
            //registered = response.data
            if(response.data.result.status == '500'){
                alert(response.data.result.message);
                this.setState(initialState);
            }else if(response.data.result.status == '200'){
                this.resetForm();
                alert('Амжилттай бүртгэгдлээ!')
            }
            console.log(response)
          })
      }
    
    validate = () => {
        console.log("validate duudsan")
        let nameError = "";
        let registerError= "";
        let senameError="";
        let familynameError="";
        let jobError="";
        let localError="";
        let locationError="";
        let phoneError="";
        let accountError= "";
        let bankError="";
        let emailError="";
        let sexError="";
        let ageError="";
        let dateError="";
        let cityError="";
        var regex = /^[\u0400-\u04FF ]+$/;
        var regex2 =/^[0-9]+$/;
        if(!/^[\u0400-\u04FF ]/.test(this.state.name) || this.state.name.trim().length == 0) {
            console.log("aldaatai ner!");
            nameError = "Нэрээ кириллээр бичнэ үү!"
            //return false;
        } else {
            nameError = ""; // Clear the error message if the input is valid
        }

        if(!/^[\u0400-\u04FF ]/.test(this.state.sename) || this.state.sename.trim().length == 0) {
            console.log("aldaata ner!");
            senameError = "Овогоо кириллээр бичнэ үү!"
            //return false;
        } else {
            senameError = ""; // Clear the error message if the input is valid
        }

        if(!/^[\u0400-\u04FF ]/.test(this.state.familyname) || this.state.familyname.trim().length == 0) {
            console.log("aldaat ner!");
            familynameError = "Ургийн овогоо кириллээр бичнэ үү!"
            //return false;
        } else {
            familynameError = ""; // Clear the error message if the input is valid
        }
        

        if(!regex2.test(this.state.phone) || this.state.phone.trim().length == 0){
            phoneError = "Утасны дугаараа зөв оруулна уу!"
        } else {
            phoneError = ""; // Clear the error message if the input is valid
        }

        if(!regex2.test(this.state.account) || this.state.account.trim().length == 0){
            accountError = "Дансны дугаараа зөв оруулна уу!"
        } else {
            accountError = ""; // Clear the error message if the input is valid
        }


        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
            console.log("aldaata email");
            emailError = 'Invalid email address'
        } else {
            emailError = ""; // Clear the error message if the input is valid
        }

        if(!regex.test(this.state.register.substr(0,2)) || !regex2.test(this.state.register.substr(2,10))
        || this.state.register.trim().length == 0 || this.state.register.length > 10){
            registerError = "Регистерийн дугаараа зөв оруулна уу!"
        } else {
            registerError = ""; // Clear the error message if the input is valid
        }

        if(this.state.bank === ''){
            bankError = "Банкаа сонгоно уу!"
        } else {
            bankError = ""; // Clear the error message if the input is valid
        }


        if(this.state.age === ''){
            ageError = "Насаа сонгоно уу!"
        } else {
            ageError = ""; // Clear the error message if the input is valid
        }


        if(this.state.sex === ''){
            sexError = "Хүйсээ сонгоно уу!"
            console.log("Хүйсээ сонгоно уу!")
            console.log(this.state.sex)
        } else {
            sexError = ""; // Clear the error message if the input is valid
        }

        if(this.state.city === ''){
            cityError = "Аймагаа сонгоно уу!"
        } else {
            cityError = ""; // Clear the error message if the input is valid
        }

        const selectedDate = new Date(this.state.date);
        const today = new Date();
        console.log(selectedDate)
        console.log(today)
        console.log(selectedDate < today)
        if(this.state.date === ''){
            dateError = "Төрсөн он, сар, өдөр оруулна уу! Жич: 02/13/1994"
        } else if (selectedDate > today) {
            dateError = "Төрсөн он, сар, өдөр нь одоогийн огноос өмнө байх ёстой!";
        } else {
            dateError = ""; // Clear the error message if the input is valid
        }


        this.setState({
            nameError,
            familynameError,
            registerError,
            phoneError,
            accountError,
            senameError,
            emailError,
            bankError,
            ageError,
            sexError,
            cityError,
            dateError
        });

        console.log("validate duusch baina!")
        console.log(nameError || familynameError || registerError ||  phoneError ||  accountError ||  senameError || emailError ||  bankError || ageError || sexError || cityError);
        return !(nameError || familynameError || registerError ||  phoneError ||  accountError ||  senameError || emailError ||  bankError || ageError || sexError || cityError || dateError);
        
    }

    resetForm = () => {
        console.log("reset duudsan")
        this.setState({
        name: '',
        sename:'',
        familyname:'',
        register: '',
        phone: '',
        bank: '',
        account: '',
        job:'',
        local:'',
        location:'',
        sex:'',
        age:'1',
        date:'',
        email:'',
        city:'',
        isSubmitting: false
      });
      console.log("resetiin daraah state: ")
      console.log(this.state)
    }

    handleOnChange = event => {
        const { name, value } = event.target;
        let error = "";
    
        switch (name) {
            case "name":
                if (name === "name" && (!/^[\u0400-\u04FF ]/.test(value) || value.trim().length === 0)) {
                    error = "Нэрээ кириллээр бичнэ үү!";
                }
                break;
            case "sename":
                if (name === "sename" && (!/^[\u0400-\u04FF ]/.test(value) || value.trim().length === 0)) {
                    error = "Овогоо кириллээр бичнэ үү!";
                }
                break;
            case "familyname":
                if (name === "familyname" && (!/^[\u0400-\u04FF ]/.test(value) || value.trim().length === 0)) {
                    error = "Ургийн овогоо кириллээр бичнэ үү!";
                }
                break;
    
            case "phone":
                if (name === "phone" && (!/^[0-9]+$/.test(value) || value.trim().length === 0)) {
                    error = `${name.charAt(0).toUpperCase() + name.slice(1)}-г зөв оруулна уу!`;
                }
                break;
            case "account":
                if (name === "account" && (!/^[0-9]+$/.test(value) || value.trim().length === 0)) {
                    error = `${name.charAt(0).toUpperCase() + name.slice(1)}-г зөв оруулна уу!`;
                }
                break;
    
            case "email":
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                    error = "И-мэйл хаяг буруу байна!";
                }
                break;
    
            case "register":
                const regex = /^[\u0400-\u04FF ]+$/;
                const regex2 = /^[0-9]+$/;
                if (!regex.test(value.substr(0, 2)) || !regex2.test(value.substr(2, 10)) || value.trim().length === 0 || value.length > 10) {
                    error = "Регистрийн дугаараа зөв оруулна уу!";
                }
                break;
    
            // Add cases for other fields...

            default:
                break;
        }
    
        // Set the error state for the specific field
        this.setState({ [`${name}Error`]: error, [name]: value });
    };
    


    handleSubmit = (event) => {
        
        event.preventDefault();
        const data = this.state;
        const isValid = this.validate();
        // const [startDate, setStartDate] = this.useState(new Date());
        console.log("isValid status: " + isValid);
        console.log(this.state);


        if(isValid){
            const newUser = {
                name: this.state.name,
                sename: this.state.sename,
                familyname: this.state.familyname,
                job: this.state.job,
                local: this.state.local,
                location: this.state.location,
                register: this.state.register,
                phone: this.state.phone,
                bank: this.state.bank,
                account: this.state.account,
                email: this.state.email,
                city: this.state.city,
                age: this.state.age,
                sex: this.state.sex,
                date: this.state.date,
            }

            if (this.state.isSubmitting) {
                return;
            }
    
            this.setState({ isSubmitting: true });


            this.register(newUser)
            .then(result => {    
                //alert(resp)
                this.setState(initialState);   
            })
            .catch(err => {
                alert('Алдаатай бүртгэгдлээ!')  ;
                this.resetForm();         
            })
            .catch(error => {
                alert('Алдаатай бүртгэгдлээ!');
                this.resetForm();      
                console.error('Error:', error);
            });
        }
    }
    
    render(){
        return (
            <form onSubmit={this.handleSubmit}>
                <div class="leg" >Бүртгэл нээх</div>
                <label class="anhaar">Банкны данс хэсэгт данс нээж байгаа хүний мэдээллийг оруулна уу. Хүүхэд бол заавал өөрийн нэр дээрх дансыг оруулна уу
                </label><br/>
                            <input class= "lastname" type= "text" name='sename' placeholder='Овог' value={this.state.sename} onChange={this.handleOnChange}></input>
                            <div style = {{fontSize: 12, color: "red"}}>{this.state.senameError}</div>
                            <input class= "sename" type="text" name="name" placeholder="Нэр" value={this.state.name} onChange={this.handleOnChange}></input><br/>
                            <div style = {{fontSize: 12, color: "red"}}>{this.state.nameError}</div>
                            <select class="selectb" name="sex"  value={this.state.sex} onChange={this.handleOnChange} >
                                <option value=''>Хүйс</option>
                                <option value="male">Эр</option>
                                <option value="female">Эм</option>
                            </select><br/>
                            <div style = {{fontSize: 12, color: "red"}}>{this.state.sexError}</div>
                            <input class= "sename" type= "text" name='familyname' placeholder='Ургийн овог' value={this.state.familyname} onChange={this.handleOnChange}></input>
                            <div style = {{fontSize: 12, color: "red"}}>{this.state.familynameError}</div>
                            <input class= "register" type="text" name="register" placeholder="Регистрийн дугаар" value={this.state.register} onChange={this.handleOnChange}></input><br/>
                            <div style = {{fontSize: 12, color: "red"}}>{this.state.registerError}</div>
                    <p1>Төрсөн он, сар, өдөр</p1><br/> 
                    <input type="date" name='date' value={this.state.date} onChange={this.handleOnChange} ref={this.dateInputRef}/><br/> 
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.dateError}</div> 
                    <input class= "sename" type= "text" name='job' placeholder='Эрхэлсэн ажил' value={this.state.job} onChange={this.handleOnChange}></input><br/>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.jobError}</div> 
                    <select class="selectb" name="city"  value={this.state.city} onChange={this.handleOnChange} >
                        <option  value="">Аймаг,нийслэл</option>
                        <option value="Ulaanbaatar">Улаанбаатар</option>
                        <option value="Arhangai">Архангай</option>
                        <option value="Bayan-ulgii">Баян-Өлгий</option> 
                        <option value="Dgogyhongor">Баянхонгор</option> 
                        <option value="Bulgan">Булган</option> 
                        <option value="Govi-Altai">Говь-Алтай</option> 
                        <option value="Govisumber">Говьсүмбэр</option>
                        <option value="Darhan">Дархан-Уул</option>
                        <option value="Dorngovi">Дорноговь</option>
                        <option value="Dornod">Дорнод</option>
                        <option value="Dundgovi">Дундговь</option>
                        <option value="Zavhan">Завхан</option>   
                        <option value="Orhon">Орхон</option>
                        <option value="Uvurhangai">Өвөрхангай	</option> 
                        <option value="Umnugovi"> Өмнөговь </option> 
                        <option value="Suhbaatar">Сүхбаатар</option> 
                        <option value="Selenge">Сэлэнгэ</option> 
                        <option value="Tov">Төв</option>
                        <option value="Uvs">Увс</option>
                        <option value="Hovd">Ховд</option>
                        <option value="Huvsgul">Хөвсгөл</option>
                        <option value="Hentii">Хэнтий</option>
                    </select><br/>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.cityError}</div>
                    <input class= "sename" type= "text" name='local' placeholder='Сум,дүүрэг' value={this.state.local} onChange={this.handleOnChange}></input>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.localError}</div>
                    <input class= "sename" type= "text" name='location' placeholder='Гэрийн хаяг' value={this.state.location} onChange={this.handleOnChange}></input>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.locationError}</div><br/>
                    <input class= "sename" type="text" name="phone" placeholder="Утас" value={this.state.phone} onChange={this.handleOnChange}></input><br/>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.phoneError}</div>
                    <input class= "sename" type= "text" name='email' placeholder='email' value={this.state.email} onChange={this.handleOnChange}></input>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.emailError}</div>
                    <select class="selectb" name="age"  value={this.state.age} onChange={this.handleOnChange} >
                        <option value="1">Насанд хүрсэн</option>
                        <option value="0">Насанд хүрээгүй</option>
                    </select><br/>
                    <select class="selectb" name="bank"  value={this.state.bank} onChange={this.handleOnChange} >
                        <option value="">Банк сонгох</option>
                        <option value="Khaan">Хаан банк</option>
                        <option value="Khas">Хас банк</option> 
                        <option value="Golomt">Голомт банк</option> 
                        <option value="TDB">Худалдаа хөгжлийн банк</option> 
                        <option value="Turiin">Төрийн банк</option> 
                        <option value="Arig">Ариг банк</option>
                        <option value="Ulaanbaatar">Улаанбаатар хотын банк</option>
                        <option value="Kapitron">Капитрон банк</option>
                        <option value="Undesnii hurungu">Үндэсний хөрөнгө оруулалтын банк</option>
                        <option value="Bogd">Богд банк</option>
                        <option value="Chingis">Чингис хаан банк</option>   
                    </select><br/>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.bankError}</div>
                    <input class= "sename" type="text" name="account" placeholder="Дансны дугаар" value={this.state.account} onChange={this.handleOnChange}></input><br/>
                    <div style = {{fontSize: 12, color: "red"}}>{this.state.accountError}</div><br/>
                    <label class='dans'> Булган Брокерийн Хаан Банк данс: 5003632634</label><br/>
                    <label class='dans'>Та энэ данс руу 10.000 төгрөг шилжүүлнэ үү!</label><br/>
                    <img class="img" /><br/>
                    <label class='dans'>Гүйлгээний утга дээр хүүхдийн өөрийнх нь регистрийн дугаар болон гүйлгээ хийсэн хүний утсыг бичнэ үү!</label><br/>
                    <label class='dans'> Таны данс ажлын өдрүүдэд нээгдэнэ.</label><br/>
                    <input class="Submit" type="submit"  disabled={this.state.isSubmitting} value="Данс нээх" />
            </form>
            
          );
    }
}

export default CreateForm;
