import React, {Component} from 'react';
import Fixform from '../FixForm';

function Fix(){
    return(
        <React.Fragment>
            <h3>Та хувьцааны мөнгө ороогүй эсвэл та дансаа алдаатай бөглөсөн бол доорх маягтыг бөглөнө үү!</h3>
            <Fixform />
        </React.Fragment>
    )
}

export default Fix;