import React, {Component} from 'react';
import Myform from './components/Myform';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Fix from './components/pages/Fix'
import Create from './components/pages/Account'
import Header from './components/layout/Header'
import Download from './components/Download';
import DownloadFile from './components/pages/DownloadFile';
import './App.css';
import { render } from 'react-dom';
import Fixform from './components/FixForm';
import CreateForm from './components/CreateForm';

class App extends Component{
  constructor(props){
    super(props);
    this.state = {apiResponse:""};
  }
  callAPI(){
    fetch("http://localhost:9000/testApi")
    .then(res => res.text())
    .then(res => this.setState({apiResponse: res}))
  }
  render() {
   
    return (
      <Router>
        <div>
          <Header/>
        </div>
        <body>
          <Route exact path="/" render={props => (
            <React.Fragment>
              <legend class="orj">Шинээр данс бүртгүүлэх бол энэ маягтийг бөглөнө үү.</legend>
              < CreateForm />
             </React.Fragment> 
          )}>


          </Route>
          <Route path="/fix" component={Fix}/>    
          <Route path="/about" component={About}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/create" component={Create}/>
        </body>  
      </Router>
      
    );
  }
  
}

export default App;
