import React, {Component} from 'react';
import Download from '../Download';

function DownloadFile(){
    return(
        <React.Fragment>
            <h3>file download!</h3>
            <Download />
        </React.Fragment>
    )
}

export default DownloadFile;