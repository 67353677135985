import React, {Component} from 'react';
import axios from 'axios'

const initialState = {
    name: '',
    password: ''
}
class Download extends Component {
    constructor(props){
        super(props)
        this.state = initialState;
    }

     register = newUser => {
        return axios
        .post('/download', {
            custName: newUser.name,
            password: newUser.password,
          })
          .then(response => {
            //registered = response.data
            if(response.data.result.message){
                alert('Регистер бүртгэгдсэн байна!')
            }else if(response.data.result.registerId){
                alert('Амжилттай бүртгэгдлээ!')
            }
            console.log(response)
          })
      }

    resetForm = () => {this.setState({
        name: '',
        password: ''
      });
    }

    handleOnChange = event => {
        console.log(event.target.value);
        this.setState({
            [event.target.name] : event.target.value
        })
    }

    handleSubmit = event => {
        //alert('A name was submitted: ' + this.state.name);
        event.preventDefault();
        const data = this.state;
        const newUser = {
            name: this.state.name,
            password: this.state.password
        }
        var resp = this.register(newUser)
        .then(result => {    
            //alert(resp)
            this.setState(initialState);   
        })
        .catch(err => {
            alert('Алдаатай бүртгэгдлээ!')  ;
            //this.resetForm();         
        })
        
            
        
    }
    render(){
        return (
            <form onSubmit={this.handleSubmit}>
              <legend class="leg">Мэдээлэл татах</legend>
                <input class="sename" type="text" name="name" placeholder="Нэр" value={this.state.name} onChange={this.handleOnChange}></input><br/>
                <input class="sename" type="text" name="register" placeholder="Нууц үг" value={this.state.password} onChange={this.handleOnChange}></input><br/>
                <input class="Submit" type="submit" value="Татах" onSubmit={this.handleSubmit}/>
              
            </form>
            
          );
    }
}

export default Download;