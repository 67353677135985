import React from 'react'

function Contact(){
    return(
        <div>
            <h1>Холбоо барих</h1>
            <span>Утас:</span>
            <span> 11324449, 99195114 </span>
            <br/>
            <span>Имэйл:</span>
            <span> bulganbd@yahoo.com </span>
            <br/>
            <span>Хаяг:</span>
            <span> Сүхбаатар дүүрэг, 3-р хороо, 9-р байрны 33 тоот </span>
            <br/>
            <span> Булган аймаг, Булган сум, Спорт цогцолборын ард</span>
            <br/>                      
        </div>
    )
}

export default Contact;