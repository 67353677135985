import React from 'react'

function About(){
    return(
        <React.Fragment>
            <h1>Бидний тухай</h1>
            <p>Булган Брокер ҮЦК нь 1991 онд байгуулагдсан Монгол улсын анхны компниудын нэг юм.</p>
        </React.Fragment>
    )
}

export default About;