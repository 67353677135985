import React, {Component} from 'react';
import './myform.css';
import axios from 'axios'

const initialState = {
    name: '',
    register: '',
    phone: '',
    bank: '',
    account: '',
    message: 'Амжилттай бүргэгдлээ!',
    type: 'success',
    nameError: '',
    registerError: '',
    phoneError: '',
    accountError: '',
    bankError: '',
    registered: ''
}
class Myform extends Component {
    constructor(props){
        super(props)
        this.state = initialState;
    }

     register = newUser => {
        return axios
        .post('/addForm', {
            custName: newUser.name,
            registerId: newUser.register,
            phoneNumber: newUser.phone,
            bankName: newUser.bank,
            accountNumber : newUser.account,
          })
          .then(response => {
            //registered = response.data
            if(response.status ==409){
                alert('Регистер бүртгэгдсэн байна!')
            }else if(response.status == 200){
                alert('Амжилттай бүртгэгдлээ!')
            }
            console.log(response)
          })
      }
    
    validate = () => {
        let nameError = "";
        let registerError= "";
        let phoneError= "";
        let accountError= "";
        let bankError="";
        var regex = /^[\u0400-\u04FF ]+$/;
        var regex2 =/^[0-9]+$/;
        if(!/^[\u0400-\u04FF ]/.test(this.state.name) || this.state.name.trim().length == 0) {
            console.log("aldaatai ner!");
            nameError = "Нэрээ кириллээр бичнэ үү!"
            //return false;
        }

        if(!regex2.test(this.state.phone) || this.state.name.trim().length == 0){
            phoneError = "Утасны дугаараа зөв оруулна уу!"
        }

        if(!regex2.test(this.state.account) || this.state.name.trim().length == 0){
            accountError = "Дансны дугаараа зөв оруулна уу!"
        }

        if(!regex.test(this.state.register.substr(0,2)) || !regex2.test(this.state.register.substr(2,10))
        || this.state.register.trim().length == 0 || this.state.register.length > 10){
            registerError = "Регистерийн дугаараа зөв оруулна уу!"
        }

        if(this.state.bank === ''){
            bankError = "Банкаа сонгоно уу!"
        }

        if(nameError){
            this.setState({nameError});
            return false;
        }

        if(phoneError){
            this.setState({phoneError});
            return false;
        }

        if(accountError){
            this.setState({accountError});
            return false;
        }

        if(registerError){
            this.setState({registerError});
            return false;
        }

        if(bankError){
            this.setState({bankError});
            return false;
        }

        return true;
    }

    resetForm = () => {this.setState({
        name: '',
        register: '',
        phone: '',
        bank: '',
        account: ''
      });
    }

    handleOnChange = event => {
        console.log(event.target.value);
        this.setState({
            [event.target.name] : event.target.value
        })
    }

    handleSubmit = event => {
        //alert('A name was submitted: ' + this.state.name);
        event.preventDefault();
        const data = this.state;
        const isValid = this.validate();
        // const isValid = true

        if(isValid){
            const newUser = {
                name: this.state.name,
                register: this.state.register,
                phone: this.state.phone,
                bank: this.state.bank,
                account: this.state.account
            }
            var resp = this.register(newUser)
            .then(result => {    
                //alert(resp)
                this.setState(initialState);   
            })
            .catch(err => {
                alert('Алдаатай бүртгэгдлээ!')  ;
                //this.resetForm();         
            })
        }
            
        
    }
    render(){
        return (
            <form onSubmit={this.handleSubmit}>
              <legend class="leg" >Данс холбох</legend>
                <input class="sename" type="text" name="name" placeholder="Нэр" value={this.state.name} onChange={this.handleOnChange}></input><br/>
                <div style = {{fontSize: 12, color: "red"}}>{this.state.nameError}</div>
                <input class="sename" type="text" name="register" placeholder="Регистрийн дугаар" value={this.state.register} onChange={this.handleOnChange}></input><br/>
                <div style = {{fontSize: 12, color: "red"}}>{this.state.registerError}</div>
                <input  class="sename" type="text" name="phone" placeholder="Утас" value={this.state.phone} onChange={this.handleOnChange}></input><br/>
                <div style = {{fontSize: 12, color: "red"}}>{this.state.phoneError}</div>
                <select class= "selectb" name="bank" onChange={this.handleOnChange} >
                    <option value="">Банк сонгох</option>
                    <option value="Khaan">Хаан банк</option>
                    <option value="Khas">Хас банк</option> 
                    <option value="Golomt">Голомт банк</option> 
                    <option value="TDB">Худалдаа хөгжлийн банк</option> 
                    <option value="Turiin">Төрийн банк</option> 
                    <option value="Arig">Ариг банк</option>
                    <option value="Ulaanbaatar">Улаанбаатар хотын банк</option>
                    <option value="Kapitron">Капитрон банк</option>
                    <option value="Undesnii hurungu">Үндэсний хөрөнгө оруулалтын банк</option>
                    <option value="Bogd">Богд банк</option>
                    <option value="Chingis">Чингис хаан банк</option>   
                </select><br/>
                <input class= "sename" type="text" name="account" placeholder="Дансны дугаар" value={this.state.account} onChange={this.handleOnChange}></input><br/>
                <div style = {{fontSize: 12, color: "red"}}>{this.state.accountError}</div><br/>
                <input class="Submit" type="submit" value="Холбох" onSubmit={this.handleSubmit}/>
            
              
            </form>
            
          );
    }
}

export default Myform;
