import { logDOM } from '@testing-library/react';
import React from 'react'
import {Link} from 'react-router-dom'
import './Header.css';
function header() {
    return (
        <div>
            <header class="headerStyle">
            {/* <img class="img" src='qpay.png'/> */}
                <div class= "nam" >БУЛГАНБРОКЕР ҮЦК ХХК</div>
                <div class="haju">
                    <Link class="linkStyle" to="/create">Данс нээх</Link> 
                    <Link class="linkStyle" to="/">Данс холбох</Link> 
                    <Link class="linkStyle" to="/fix">Данс засварлах</Link> 
                    <Link class="linkStyle" to="/about">Бидний тухай</Link> 
                    <Link class="linkStyle" to="/contact">Холбоо барих</Link> 
                </div>
            </header>
        </div>
    )
}


export default header;