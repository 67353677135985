import React, {Component} from 'react';
import CreateForm from '../CreateForm';

function Create(){
    return(
        <React.Fragment>
            <h3>Шинээр данс бүртгүүлэх бол энэ маягтийг бөглөнө үү.</h3>
            <CreateForm />
        </React.Fragment>
    )
}

export default Create;